import { render, staticRenderFns } from "./RealTimePersonalization.vue?vue&type=template&id=ff3eb4b8&scoped=true&"
import script from "./RealTimePersonalization.vue?vue&type=script&lang=js&"
export * from "./RealTimePersonalization.vue?vue&type=script&lang=js&"
import style0 from "./RealTimePersonalization.vue?vue&type=style&index=0&id=ff3eb4b8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff3eb4b8",
  null
  
)

export default component.exports