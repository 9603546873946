<template>
  <svg viewBox="0 0 50 50" class="icon">
    <g>
      <path
        d="M44.4,10.8l-6.6,14.3H21.8L12.2,7.8c-0.6-0.6-1.2-1.2-1.8-1.2H0.9v3.6h8.4l9.5,17.3c0.6,0.6,1.2,1.2,1.8,1.2
		h18.5c0.6,0,1.2-0.6,1.8-1.2l7.8-16.7H44.4z"
      />
    </g>
    <circle cx="21.2" cy="34.7" r="3" />
    <circle cx="36.1" cy="34.7" r="3" />
  </svg>
</template>

<script>
export default { name: 'CartIcon' };
</script>

<style scoped>
.icon {
  fill: var(--grey-600);
}
</style>
