import { render, staticRenderFns } from "./PoweredByAWS.vue?vue&type=template&id=4ea658fc&scoped=true&"
import script from "./PoweredByAWS.vue?vue&type=script&lang=js&"
export * from "./PoweredByAWS.vue?vue&type=script&lang=js&"
import style0 from "./PoweredByAWS.vue?vue&type=style&index=0&id=4ea658fc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ea658fc",
  null
  
)

export default component.exports