<template>
  <div aria-hidden class="separator"></div>
</template>

<script>
export default {
  name: 'NavSeparator',
};
</script>

<style scoped>
.separator {
  width: 1px;
  height: 50px;
  background: var(--grey-300);
}
</style>
