<template>
  <div class="terms-and-conditions">
    <p class="mb-1">
      애니커머스는 데모용 웹 페이지입니다. 애니커머스에서 제공하는 모든 콘텐츠는 가상의 콘텐츠이며 데모로 제공하기 위한 용도입니다.
    </p>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions',
};
</script>

<style scoped>
.terms-and-conditions {
  font-size: 0.85rem;
}
</style>
