<template>
	<a href="/" aria-label="home"><img src="/logo-footer.png" width="200" class="logo" /></a>
</template>

<script>
export default {
	name: 'PoweredByAWS',
};
</script>

<style scoped>
.icon {
	fill: #252f3e;
}

.st1 {
	fill: #ff9900;
}
</style>
